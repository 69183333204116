@import "../../scss/base.scss";

.options {
  box-sizing: border-box;
  width: 442px;
  position: absolute;
  top: calc(100% + 35px);
  left: -20px;
  background: #ffffff;
  border-radius: 8px;
  border: 0.5px solid rgba(57, 57, 57, 0.3);
  @include mobile {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50px;
    border: none;
    border-radius: 0px;
    left: 0;
  }

  .arrow, ._arrow {
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    border-radius: 4px 0 0 0;
    transform: rotate(45deg);
    border-top: 0.5px solid rgba(57, 57, 57, 0.3);
    border-left: 0.5px solid rgba(57, 57, 57, 0.3);
    background: #ffffff;
    position: absolute;
    top: -7px;
    left: 23px;
    @include mobile {
      display: none;
    }
  }
  ._arrow{
    left: 14vw;
  }

  .header, .highHeader {
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    padding: 0px 25px;
    display: flex;
    align-items: flex-end;
    border-bottom: 0.5px solid rgba(57, 57, 57, 0.3);
    @include mobile {
      height: 50px;
      padding: 25px 20px 0 20px;

      .backIcon {
        height: 40px;
        margin-right: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .section {
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      @include mobile {
        height: 30px;
      }

      > h4 {
        margin: 0;
        font: 20px Circular Std Book, Helvetica, Arial, sans-serif;
        font-weight: bold;
        color: #2d303d;
        @include mobile {
          font-size: 16px;
        }
      }

      .selected {
        height: 5px;
        border-radius: 2.5px;
        background-color: #ff1e57;
        @include mobile {
          height: 3px;
          border-radius: 1.5px;
        }
      }
      .notSelected {
        height: 5px;
        border-radius: 2.5px;
        background-color: #ffffff;
        @include mobile {
          height: 3px;
          border-radius: 1.5px;
        }
      }
    }
    .section:active {
      .notSelected {
        background-color: #ff1e57;
      }
    }
    .section:hover{
      .notSelected {
        background-color: #FFA7BD;
      }
    }
  }

  .highHeader{
    @include mobile {
      height: 95px;
    }
  }

  .browse {
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: #a6e8cd;
    box-shadow: 0px 2px 10px #e3e2dc;

    input {
      box-sizing: border-box;
      width: 100%;
      height: 50px;
      padding: 0 25px;
      display: flex;
      align-items: center;
      outline: none;
      border: none;
      border-radius: 25px;
      font: 16px Circular Std Book, Helvetica, Arial, sans-serif;
      color: rgb(57, 57, 57, 0.7);
    }
    .clear {
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: flex;
      position: absolute;
      right: 25px;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      cursor: pointer;

      @include mobile {
        margin-right: 5px;
        >img{
          width: 25px;
        }
      }
    }
  }

  .content {
    box-sizing: border-box;
    height: calc(630px - 70px);
    padding: 15px 25px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    @include mobile {
      padding: 10px 20px 0px 20px;
      &.search {
        height: calc(100vh - 180px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      &.categories {
        height: calc(100vh - 100px);
      }
    }

    .title {
      height: 40px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.5px solid rgba(57, 57, 57, 0.3);
      font: 16px Circular Std Book, Helvetica, Arial, sans-serif;
      font-weight: bold;
      line-height: 20px;
      color: #2d303d;
      @include mobile {
        font-size: 16px;
        margin-top: 10px;
        line-height: 35px;
      }

      .any {
        display: flex;
        align-items: center;
        font-weight: normal;
        cursor: pointer;

        .checkbox {
          box-sizing: border-box;
          width: 18px;
          height: 18px;
          margin-left: 5px;
          border-radius: 9px;
          &.notSelected {
            border: 0.5px solid #393939;
          }
          &.selected {
            background-color: #ff1e57;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("/search/check-mark-white.svg");
          }
        }
      }
      .any:hover {
        .checkbox {
          background-color: #e51b4e;
          border: none;
        }
      }
    }

    .option {
      display: flex;
      align-items: center;
      position: relative;
      font: 18px Circular Std Book, Helvetica, Arial, sans-serif;
      color: #2d303d;
      margin: 5px 0;
      cursor: pointer;
      

      .imgWrapper {
        > .profilePicture {
          width: 65px;
          height: 65px;
          margin-right: 15px;
          object-fit: cover;
          &.unavailable {
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%); // Safari 6.0 - 9.0
          }
        }
  
        > .picturePlaceholder {
          width: 65px;
          height: 65px;
          margin-right: 15px;
        }

      }

      > .iconContainer {
        width: 65px;
        height: 65px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 4px;
      }

      .info {
        display: flex;
        flex-direction: column;

        > h3 {
          margin: 0;
          font: 18px Circular Std Book, Helvetica, Arial, sans-serif;
          font-weight: bold;
          &.unavailable {
            color: #393939;
            opacity: 0.7;
          }
          @include mobile {
            font-size: 16px;
          }
        }

        > p {
          margin: 0;
          font: 16px Circular Std Book, Helvetica, Arial, sans-serif;
          line-height: 20px;
          &.unavailable {
            color: #ff1e57;
          }
          @include mobile {
            font-size: 14px;
          }
        }
      }

      .availableIcon {
        display: none;
        width: 9px;
        margin-left: auto;
      }
      .unavailableIcon {
        margin-left: auto;
       position: relative;
        
      }
      .notificationBubble {
        box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    min-height: 128px;
    left: 145px;
    top: 60px;
    padding: 15px;
    z-index: 1000;
    background-color: #C6F6E3;
    border-radius: 8px;
    @include mobile{
      width: 200px;
    }
        p {
          margin: 0 0 5px 0;
          text-align: center;
          font-family: Circular Std Book, Arial, Helvetica, sans-serif;
          font-size: 16px;
          line-height: 20px;
          /* Black */
          color: #393939;
          @include mobile{
            font-size: 14px;
          }
        }
        .bubbleButton {
          display:flex;
          justify-content: center;
          align-items: center;
          width: 150px;
          height: 40px;
          /* Red/Red */
          background: $baseRedColor;
          border-radius: 45px;
          /* BG/White */
          color: #FFFFFF;
          font-family: Circular Std Book, Arial, Helvetica, sans-serif;
          font-size: 16px;
          line-height: 20px;
          @include mobile{
            width: 120px;
            font-size: 14px;
          }
        }
      }
    }
    .option:hover {
      color: #00ad66;
      .availableIcon {
        display: flex;
      }
    }

    .category {
      margin: 12.5px 0;
    }

    .banner {
      box-sizing: border-box;
      width: 100%;
      padding: 20px;
      padding-right: 0;
      padding-bottom: 10px;
      margin-top: 40px;
      margin-bottom: 20px;
      background: #fff7e8;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 353px) {
        padding-top: 10px;
        padding-left: 10px;
      }

      .bannerContent {
        display: flex;
        flex-direction: column;
        justify-content: center;

        > h3 {
          margin: 0;
          font: 16px Circular Std Bld, Helvetica, Arial, sans-serif;
          color: #6323b7;
          text-align: left;
          @media (max-width: 353px) {
            font-size: 14px;
          }
        }
        > h5 {
          margin: 0;
          font: 14px Circular Std Book, Helvetica, Arial, sans-serif;
          color: #393939;
          margin-bottom: 10px;
          text-align: left;
          @media (max-width: 353px) {
            font-size: 12px;
          }
        }
      }
      .imageContainer {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 90px;
        height: 100px;
        img {
          width: 136px;
          position: relative;
          left: 20px;
        }
      }

      button {
        width: 175px;
        @media (max-width: 353px) {
          width: 150px;
        }
      }
    }

    .noMatching {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 45px;
      @include mobile {
        // flex-direction: row;
        // margin-top: 10px;
        // margin-bottom: 35px;
      }

      img {
        margin-bottom: 15px;
        @include mobile {
          width: 40px;
       
          margin-right: 15px;
        }
      }

      p {
        width: 50%;
        margin: 0;
        font: 18px Circular Std Book, Helvetica, Arial, sans-serif;
        font-weight: bold;
        line-height: 23px;
        text-align: center;
        color: #393939;
        @include mobile {
          width: 100%;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
        }
      }
    }
  }
  .content::-webkit-scrollbar {
    -webkit-appearance: none;
    -webkit-overflow-scrolling: auto !important;
    width: 5px !important;
  }
  
  .content::-webkit-scrollbar:vertical {
    width: 5px;
  }
  
  .content::-webkit-scrollbar-button:increment,
  .content::-webkit-scrollbar-button {
    display: none;
  }
  
  .content::-webkit-scrollbar:horizontal {
    height: 80px;
  }
  .content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #39393963;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  
  .content::-webkit-scrollbar-thumb:window-inactive {
    border-radius: 4px !important;
    background-color: #39393963 !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
  }
  .content::-webkit-scrollbar-track{
    border-radius: 4px;
    background-color: #ffff;
  
  }
  .dataContent::-webkit-scrollbar {
    -webkit-appearance: none;
    -webkit-overflow-scrolling: auto !important;
    width: 5px !important;
  }
  .dataContent::-webkit-scrollbar:vertical {
    width: 5px;
  }
  
  .dataContent::-webkit-scrollbar-button:increment,
  .dataContent::-webkit-scrollbar-button {
    display: none;
  }
  
  .dataContent::-webkit-scrollbar:horizontal {
    height: 80px;
  }
  .dataContent::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #39393963;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  
  .dataContent::-webkit-scrollbar-thumb:window-inactive {
    border-radius: 4px !important;
    background-color: #39393963 !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5) !important;
  }
  .dataContent::-webkit-scrollbar-track{
    border-radius: 4px;
    background-color: #ffff;
  
  }
}
