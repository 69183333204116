@import "../../scss/base.scss";

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  background:
    linear-gradient(#00AD66, #cad8d3) border-box;
  border-width: 10px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  mask:
    linear-gradient(#ada9a9, #ada9a9) padding-box,
    linear-gradient(#ada9a9, #ada9a9);
  mask-border: 55%;
  mask-composite: exclude;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  // Size
  &.tall {
    width: 45px;
    height: 45px;

    @include mobile {
      width: 30px;
      height: 30px;
    }
  }

  &.medium {
    width: 30px;
    height: 30px;

    @include mobile {
      width: 20px;
      height: 20px;
    }
  }

  &.short {
    width: 20px;
    height: 20px;

    @include mobile {
      width: 10px;
      height: 10px;
    }
  }
}