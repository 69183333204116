@import "../../scss/base.scss";

.bar {
  box-sizing: border-box;
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a6e8cd;
  box-shadow: 4px 4px 10px rgba(57, 57, 57, 0.1);
  position: relative;
  z-index: 10;

  @include tablet {
    padding: 0 30px;
  }

  @include mobile {
    padding: 0 10px;
    height: 64px;
  }

  .formsContainer {
    box-sizing: border-box;
    width: 100%;
    max-width: 1074px;
    height: 68px;
    padding: 2px 8px 2px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 10px;
    font:
      18px Circular Std Bld,
      Helvetica,
      Arial,
      sans-serif;
    color: $baseBlack;
    @include mobile {
      box-sizing: border-box;
      height: 46px;
      padding: 2px 4px 2px 12px;
      margin: 0 auto;
      font-size: 14px;
      line-height: 18px;
    }

    .search {
      width: 50%;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;

      @include tablet {
        width: 55%;
      }

      @include mobile {
        width: 50%;
      }
    }

    button {
      @include mobile {
        padding: 15px;
        margin-right: 1px;
      }
    }

    .zipcode {
      box-sizing: border-box;
      width: 50%;
      padding-left: 15px;
      border-left: 0.5px solid #393939;

      @include tablet {
        width: 45%;
      }

      @include mobile {
        width: 50%;
        padding-left: 10px;
      }

      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .inputContainer {
      width: 100%;
      margin-right: 30px;
      display: flex;
      flex-direction: column;

      @include mobile {
        margin-right: 10px;
      }

      label,
      .label {
        font-size: 16px;
        font-family:
          Circular Std Bld,
          Helvetica,
          Arial,
          sans-serif;

        @include mobile {
          font-size: 14px;
        }
      }

      .empty {
        height: 18px;
        font-size: 14px;
        color: rgba(57, 57, 57, 0.7);
        font-family:
          Circular Std Book,
          Helvetica,
          Arial,
          sans-serif;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input {
        padding: 0;
        width: 100%;
        border: none;
        outline: none;
        font-size: 16px;
        font-family:
          Circular Std Book,
          Helvetica,
          Arial,
          sans-serif;
        color: rgba(57, 57, 57, 0.7);

        @include mobile {
          font-size: 14px;
          color: #393939;
        }
      }

      input:disabled {
        text-decoration: none;
        -webkit-text-fill-color: none;
        color: rgba(57, 57, 57, 0.7);
        opacity: 1;
        /* required on iOS */
        background: #ffffff;
        @include mobile {
          font-size: 14px;
          color: #393939;
        }
      }
    }

    .clear {
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: linear-gradient(
          0deg,
          rgba(57, 57, 57, 0.5),
          rgba(57, 57, 57, 0.5)
        ),
        #ffffff;
      border-radius: 15px;
      cursor: pointer;

      @include mobile {
        margin-right: 5px;
        > img {
          width: 25px;
        }
      }
    }
  }

  &.home-unpinned {
    height: auto;
    background: none;
    box-shadow: none;

    > .formsContainer {
      height: 70px;
      margin: 0;
      padding: 0 10px 0 30px;
      border-radius: 10px;
      box-shadow: 0px 8px 16px rgba(57, 57, 57, 0.2);

      @include mobile {
        height: 60px;
        padding: 0 9px 0 20px;
        border-radius: 10px;
      }

      > .search {
        > div:last-child {
          top: calc(100% + 30px);
          left: -20px;

          @include mobile {
            top: 50px;
            height: 100%;
            left: 0;
          }
        }
      }

      > .zipcode {
        > div:first-child {
          > button {
            @include mobile {
              padding: 0 21px;
            }
          }
        }
      }
    }
  }

  &.home-pinned-with-banner {
    position: fixed;
    top: 104px;
    left: 0;
    z-index: 10;
    transition: top 0.35s;
    @include mobile {
      top: 100px;
    }

    @include mobile {
      button {
        width: 20px !important;
        height: 20px !important;
      }
    }

    &.hidden {
      top: -35px;
    }
  }

  &.home-pinned {
    position: fixed;
    top: 65px;
    left: 0;
    z-index: 10;
    transition: top 0.35s;
    @include mobile {
      top: 50px;
    }

    @include mobile {
      top: 50px;
      button {
        width: 20px !important;
        height: 20px !important;
      }
    }

    &.hidden {
      top: -35px;
    }
  }
}
.errorZipcode {
  background-color: #ff1e57;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-family:
    Circular Std Book,
    Helvetica,
    Arial,
    sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    margin-right: 10px;
  }
  @include mobile {
    font-size: 16px;
  }
}
.errorZipCodeHome,
.errorZipCodeHomePin {
  background-color: #ff1e57;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  font-family:
    Circular Std Book,
    Helvetica,
    Arial,
    sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  top: 65px;
  width: 100%;
  left: 0;
  > img {
    margin-right: 10px;
  }
  @include mobile {
    font-size: 16px;
    top: 50px;
  }
}
.errorZipCodeHomePin {
  top: 139px;
  @include mobile {
    top: 100px;
  }
}
.background {
  background: #393939;
  opacity: 0.4;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;

  @include mobile {
    display: none;
  }
}
